import Post from '../components/post'
import ProTip from '../components/proTip'
import React from 'react'
import {Fade, Grid, Typography} from '@material-ui/core'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {useAuth0} from '@auth0/auth0-react'
import Loading from '../components/loading'
import {gql, useMutation} from '@apollo/client';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1.4),
      marginTop: theme.spacing(11)
    },
    please: {
      marginBottom: theme.spacing(2),
    },
    icon: {
      maxWidth: 24,
      maxHeight: 24,
      verticalAlign: 'middle',
      marginRight: theme.spacing(1),
    },
    link: {
      color: '#C1C1C1'
    }
  }),
)

const CREATEORUPDATEPROPFILE = gql`
    mutation createdOrUpdateProfile($avatar: String!, $userId: String!) {
        createOrUpdateProfile(input: {
            avatar: $avatar,
            userId: $userId
        }) 
        {
          id
        }
    }
`;

export default function Home() {
  let username = 'Anon'
  const classes = useStyles()
  const { user, isAuthenticated, isLoading } = useAuth0()
  const [checkCreateOrUpdateProfile, { data, error }] = useMutation(CREATEORUPDATEPROPFILE);

  if (isLoading) {
    return <Loading />
  }

  if (user) {
    if (user.nickname != null) {
      username = user.nickname
    }
  }

  if(isAuthenticated) {
    checkCreateOrUpdateProfile({
      variables: {
        userId: username,
        avatar: user?.picture
      }
    })
  }

  return (
    <Fade in>
      <Grid
        className={classes.root}
        container
        spacing={0}
        direction='column'
        alignItems='center'
        justify='center'
      >
        <Typography className={classes.please} color='textSecondary'>
          Welcome to Animoshi, {username}!
        </Typography>
        <Grid item xs={12} sm={8} lg={3} xl={3}>
          <Post />
          <ProTip />
        </Grid>
      </Grid>
    </Fade>
  )
}
