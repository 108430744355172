import React from 'react'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {Typography} from '@material-ui/core'
import {StyledDivider} from './divider';
import Moment from 'moment';
import {PostedTime} from './general/postedTime';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
    },
    comment: {
      flexWrap: 'wrap',
      maxWidth: '100%',
      wordWrap: 'break-word'
    },
    icon: {
      maxHeight: 30,
      marginRight: theme.spacing(0.5),
      position: 'relative',
      bottom: 0,
      left: 2
    },
  }),
)

interface IComment {
  key: string
  id: string
  entityId: string
  text: string
  userId: string
  createdTime: string
}

export function Comments(props: IComment) {
  const classes = useStyles();
  const moment = Moment;
  return (
    <div key={props.key} >
      <StyledDivider/>
      <Typography className={classes.comment}>
        {props.text}
      </Typography>
      <Typography className={classes.icon} variant='subtitle2' color={'textSecondary'}>

        <PostedTime createdTime={props.createdTime}
                    userId={props.userId}
                    suffix={false} />

      </Typography>
    </div>
  )
}
