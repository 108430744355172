import React from 'react'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import Moment from 'moment';
import {Link} from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: '#C7C7C7',
      textDecoration: 'underline'
    }
  }),
)

export function PostedTime(props: {createdTime: string, userId: string, suffix: boolean}) {
  const moment = Moment;
  const classes = useStyles();

  return (
    <span>
      — <Link className={classes.link} to={`/profile/${props.userId}`}>{props.userId}</Link> • {moment(parseInt(props.createdTime)).fromNow(props.suffix)}
    </span>
  )
}
