import React, {useState} from 'react'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {Button, Card, CardContent, Fade, Grid, Typography} from '@material-ui/core'
import {gql, useMutation, useQuery} from '@apollo/client'
import Loading from '../components/loading'
import AlertNotification from '../components/errors/generic';
import {useAuth0} from '@auth0/auth0-react';
import {useHistory, useParams} from 'react-router-dom';
import {PostCard} from '../components/cards/postCard';
import {useForm} from 'react-hook-form';
import {Comments} from '../components/comments';
import {PostCommentForm} from '../components/forms/postCommentForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(1.4),
      marginTop: theme.spacing(8)
    },
    backButton: {
      margin: theme.spacing(0, 0, 1, 0),
    },
    commentButton: {
      float: 'right',
      position: 'relative',
      bottom: 3,
    },
    commentForm: {
      marginTop: theme.spacing(-3),
      marginBottom: theme.spacing(6),
    },
  }),
)

const POST = gql`
    query getPosts($id: ID!) {
        findPost(id: $id) {
            id
            content
            title
            image
            video
            likes
            comments
            nsfwToggle
            userId
            createdTime
        }
    }
`

const LIKEPOST = gql`
    mutation likePost($postId: String!, $userId: String!) {
        likePost(input: {
            postId: $postId,
            userId: $userId
        })
    }
`;

const POSTCOMMENTS = gql`
    query getPostComments($postId: ID!, $offset: Int!, $limit: Int!) {
        findAllPostCommentsByPostId(postId: $postId, offset: $offset, limit: $limit) {
            postId
            text
            userId
            createdTime
        }
    }
`

const COMMENTPOST = gql`
    mutation publishNewPostComment($postId: ID!, $userId: String!, $text: String!) {
        publishPostComment(input: {
            postId: $postId,
            userId: $userId,
            text: $text
        }) {
            id
        }
    }
`;

interface IComment {
  id: string
  postId: string
  text: string
  userId: string
  createdTime: string
}

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export default function SinglePost() {
  let username = 'Anon';
  const history = useHistory()
  const postId = useParams();
  const classes = useStyles()
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [authError, setAuthError] = useState(false)
  const [showCommentForm, setShowCommentForm] = useState(false);
  const { handleSubmit: handleCommentSubmit, reset: commentReset, control: commentControl } = useForm();

  function addNewComment(data: any) {
    if(isAuthenticated) {
      publishPostComment({variables:{postId: postId['postId'], text: data.comment, userId: username}})
      setAuthError(false)
      setShowCommentForm(false)
      commentReset()
    } else {
      setAuthError(true)
    }
  }

  async function addLikePost(postId: string, userId: string) {
    if (isAuthenticated) {
      likePost({variables: {postId: postId, userId: userId}})
      setAuthError(false)
    } else {
      setAuthError(true)
      await sleep(6000)
      setAuthError(false)
    }
  }

  const [likePost, { data: likeData, error: likePostError }] = useMutation(LIKEPOST, {
    refetchQueries: [
      {
        query: POST,
        variables: { id: postId['postId'] }
      }
    ]
  });

  const [publishPostComment, { data: commentPostData, error: commentPostError }] = useMutation(COMMENTPOST, {
    refetchQueries: [
      {
        query: POSTCOMMENTS,
        variables: { limit: 8, offset: 0, postId: postId['postId'] }
      },
      {
        query: POST,
        variables: { id: postId['postId'] }
      }
    ]
  });

  const { loading, error, data, fetchMore } = useQuery(POST, {
    variables: { id: postId['postId'] }
  })

  const { loading: commentsLoading, error: commentsError, data: commentData,
    fetchMore: commentsFetchMore, refetch: refetchPostComments } = useQuery(POSTCOMMENTS, {
    variables: { limit: 8, offset: 0, postId: postId['postId'] }
  });

  if (loading) return <Loading />
  if (error) return <AlertNotification severity={'error'} text={'Error loading Posts, please try again later!'}/>;
  if (!data) return <p>Not found</p>

  if (user) {
    if (user.nickname != null) {
      username = user.nickname
    }
  }

  return (
    <Fade in>
      <Grid className={classes.root} container alignItems='center' justify='center'>
        {likeData && <AlertNotification severity={'success'} text={'Post liked!'}/>}
        {authError && <AlertNotification severity={'error'} text={'You must be logged in to like posts!'}/>}
        {likePostError && <AlertNotification severity={'warning'} text={likePostError.message}/>}

        {commentPostData && <AlertNotification severity={'success'} text={'Comment posted!'}/>}
        {commentPostError && <AlertNotification severity={'warning'} text={commentPostError.message}/>}

        <Grid item xs={12} lg={4} xl={4} key={data.findPost.id}>
          <Button variant={'outlined'} color="secondary" className={classes.backButton}
                  onClick={() => history.goBack()}>
            BACK
          </Button>
          <Fade in>
            <div>
              <PostCard key={data.findPost.id}
                        id={data.findPost.id}
                        title={data.findPost.title}
                        content={data.findPost.content}
                        image={data.findPost.image}
                        video={data.findPost.video}
                        likes={data.findPost.likes}
                        comments={data.findPost.comments}
                        linkEnabled={false}
                        nsfwToggle={data.findPost.nsfwToggle}
                        enableNfswToggle={false}
                        userId={data.findPost.userId}
                        createdTime={data.findPost.createdTime}
                        likePost={() => addLikePost(data.findPost.id, username)} />

              <Card className={classes.commentForm}>
                <CardContent>
                  <Typography gutterBottom variant='h5' component='div'>
                    Comments
                    {
                      isAuthenticated && (
                        <Button className={classes.commentButton} variant="contained" color="primary"
                                onClick={() => {setShowCommentForm(true)}}>
                          + NEW COMMENT
                        </Button>
                      )
                    }
                    {
                      showCommentForm && (
                        <PostCommentForm control={commentControl}
                                         onSubmit={handleCommentSubmit(addNewComment)}/>
                      )
                    }
                  </Typography>
                  {
                    !isAuthenticated && (
                      <div>
                        <Typography color='textSecondary'>
                          Please log in to comment!
                        </Typography>
                      </div>
                    )
                  }
                  {commentData &&
                    commentData.findAllPostCommentsByPostId.map((comment: IComment) => (
                      <Comments key={comment.postId}
                                id={comment.postId}
                                entityId={comment.postId}
                                text={comment.text}
                                userId={comment.userId}
                                createdTime={comment.createdTime} />
                    ))}
                </CardContent>
              </Card>
            </div>

          </Fade>
        </Grid>
      </Grid>
    </Fade>
  )
}
