import React from 'react'
import {useAuth0} from '@auth0/auth0-react'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {Button} from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rightButton: {
      float: 'right',
      color: 'white',
      marginLeft: 'auto',
    },
  }),
)

const LogoutButton = () => {
  const classes = useStyles()
  const { logout } = useAuth0()

  return (
    <Button
      className={classes.rightButton}
      onClick={() => logout({ returnTo: window.location.origin })}
    >
      Log Out
    </Button>
  )
}

export default LogoutButton
