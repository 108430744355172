import React from 'react'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {Card, CardActionArea, CardContent, Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {StyledDivider} from '../divider';
import PostImage from '../postImage';
import YouTube from 'react-youtube';
import {Likes} from '../general/likes';
import {Comments} from '../general/comments';
import {PostedTime} from '../general/postedTime';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(6),
      display: 'flex',
    },
    media: {
      width: '100%',
    },
    postTitle: {
      color: 'white',
      textDecoration: 'none'
    },
    userId: {
      position: 'relative',
      bottom: 0,
      right: -10,
      margin: theme.spacing(1, 1, 1, 1),
    },
    postLink: {
      color: '#C3C3C3',
      textDecoration: 'none'
    }
  }),
)

const videoOptions = {
  height: '450',
  width: '100%',
  playerVars: {
    autoplay: 0
  }
};

interface Post {
  id: string,
  title?: string,
  content?: string,
  image?: string,
  video?: string,
  linkEnabled: boolean,
  likes: number,
  comments: number,
  userId: string,
  createdTime: string,
  likePost: any

  enableNfswToggle: boolean
  nsfwToggle?: number
}

export function PostCard(props: Post) {
  const classes = useStyles();

  return (
    <Card className={classes.root} key={props.id}>
      <div className={classes.media}>
        {
          props.linkEnabled && (
            <CardActionArea>
              <Link className={classes.postTitle} to={`/post/${props.id}`}>
                { (props.title !== '' || props.content !== '') && (
                  <CardContent>
                    <Typography variant='h5' component='div'>
                      {props.title}
                    </Typography>
                    { (props.content !== '') && (
                    <span>
                      <StyledDivider />
                      <Typography variant='body2'>{props.content}</Typography>
                    </span>
                    )}
                  </CardContent>
                )}
                {props.image != '' && props.image != null && <PostImage enableNfswToggle={props.enableNfswToggle} nsfwToggle={props.nsfwToggle} postImage={props.image} />}
                {props.video != '' && props.video != null && <YouTube videoId={props.video} opts={videoOptions}/>}
              </Link>
            </CardActionArea>
          )
        }

        {
          !props.linkEnabled && (
            <div>
              { (props.title !== '' || props.content !== '') && (
                  <CardContent>
                    <Typography variant='h5' component='div'>
                      {props.title}
                    </Typography>
                    { (props.content !== '') && (
                      <span>
                      <StyledDivider />
                      <Typography variant='body2'>{props.content}</Typography>
                    </span>
                    )}
                  </CardContent>
                )}
                {props.image != '' && props.image != null && <CardActionArea>
                  <PostImage postImage={props.image} enableNfswToggle={props.enableNfswToggle} nsfwToggle={props.nsfwToggle} />
                </CardActionArea>}
                {props.video != '' && props.video != null && <YouTube videoId={props.video} opts={videoOptions}/>}
            </div>
          )
        }

        <Typography variant='subtitle2' color='textSecondary' className={classes.userId}>

          <Likes likes={props.likes}
                 id={props.id}
                 userId={props.userId}
                 onClick={() => props.likePost()} />

          <Link className={classes.postLink} to={`/post/${props.id}`}>
            <Comments comments={props.comments}
                      id={props.id}
                      userId={props.userId}
                      onClick={() => console.log('')} />
          </Link>

          <PostedTime createdTime={props.createdTime}
                      userId={props.userId}
                      suffix={true} />

        </Typography>
      </div>
    </Card>
  )
}
