import React from 'react'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {Button, Card, Modal, TextField, Typography} from '@material-ui/core';
import {StyledDivider} from '../divider';
import {Controller} from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(6),
      display: 'flex',
    },
    waifuFormModal: {
      height: '70%',
      width: 600,
      position: 'absolute',
      margin: 'auto',
      overflowY: 'auto',
    },
    waifuForm: {
      padding: theme.spacing(2),
      margin: 'auto',
    },
    addWaifuSubmitButton: {
      marginTop: theme.spacing(1)
    },
  }),
)

interface RateWaifu {
  open: boolean,
  handleClose: any,
  control: any,
  onSubmit: any,
}

export function WaifuRateForm(props: RateWaifu) {
  const classes = useStyles();

  return (
    <Modal
      className={classes.waifuFormModal}
      open={props.open}
      onClose={props.handleClose}
    >
      <Card className={classes.waifuForm}>
        <Typography gutterBottom variant='h5' component='div'>
          Rate this Waifu
        </Typography>
        <StyledDivider/>
        <form>
          <Controller
            name={'rating'}
            control={props.control}
            render={({ field: { onChange, value } }) => (
              <TextField onChange={onChange} value={value} label={'Rating'} />
            )}
          />
          <br/>
          <Controller
            name={'mommyRating'}
            control={props.control}
            render={({ field: { onChange, value } }) => (
              <TextField onChange={onChange} value={value} label={'Mommy Rating'} />
            )}
          />
        </form>
        <Button className={classes.addWaifuSubmitButton} variant="contained" color="primary" onClick={props.onSubmit}>Submit</Button>
      </Card>
    </Modal>
  )
}
