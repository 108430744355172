import React, {useState} from 'react'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {Button, Card, CardContent, Fade, Grid, Modal, Typography,} from '@material-ui/core'
import Loading from '../components/loading';
import {gql, useMutation, useQuery} from '@apollo/client';
import Moment from 'moment'
import AlertNotification from '../components/errors/generic'
import {useAuth0} from '@auth0/auth0-react';
import {useForm} from 'react-hook-form';
import {useHistory, useParams} from 'react-router-dom';
import {Comments} from '../components/comments';
import {WaifuCommentForm} from '../components/forms/waifuCommentForm';
import {WaifuRateForm} from '../components/forms/waifuRateForm';
import {WaifuCard} from '../components/cards/waifuCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(1.4),
      marginTop: theme.spacing(8)
    },
    waifu: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    waifuImageModal: {
      height: '90%',
      position: 'absolute',
      margin: 'auto',
      overflowY: 'auto',
    },
    waifuImageModalImage: {
      height: '100%',
      margin: 'auto',
      display: 'block',
      maxWidth: '90%',
      maxHeight: '90%',
    },
    backButton: {
      margin: theme.spacing(0, 0, 1, 1),
    },
    commentButton: {
      float: 'right',
      position: 'relative',
      bottom: 3,
    },
  }),
)

interface IComment {
  id: string
  waifuId: string
  text: string
  userId: string
  createdTime: string
}

const WAIFU = gql`
    query getWaifu($id: ID!) {
        findWaifu(id: $id) {
            id
            name
            description
            image
            rating
            mommyMeter
            favorites
            comments
            userId
            createdTime
        }
    }
`

const WAIFUCOMMENTS = gql`
    query getWaifuComments($waifuId: ID!, $offset: Int!, $limit: Int!) {
        findAllWaifuCommentsByWaifuId(waifuId: $waifuId, offset: $offset, limit: $limit) {
            waifuId
            text
            userId
            createdTime
        }
    }
`

const FAVORITEWAIFU = gql`
    mutation favoriteWaifu($waifuId: String!, $userId: String!) {
        favoriteWaifu(input: {
            waifuId: $waifuId,
            userId: $userId
        })
    }
`;

const RATEWAIFU = gql`
    mutation rateWaifu($waifuId: String!, $userId: String!, $rating: Int!, $mommyRating: Int!) {
        rateWaifu(input: {
            waifuId: $waifuId,
            userId: $userId,
            rating: $rating,
            mommyRating: $mommyRating
        }) {
            id
        }
    }
`;

const COMMENTWAIFU = gql`
    mutation publishNewWaifuComment($waifuId: ID!, $userId: String!, $text: String!) {
        publishWaifuComment(input: {
            waifuId: $waifuId,
            userId: $userId,
            text: $text
        }) {
            id
        }
    }
`;

export default function SingleWaifu() {
  let username = 'Anon';
  const history = useHistory();
  const waifuId = useParams();
  const classes = useStyles();
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [authError, setAuthError] = useState(false);
  const [showCommentForm, setShowCommentForm] = useState(false);
  const [rateWaifuId, setRateWaifuId] = useState(null);

  const [waifuModalOpen, setWaifuModalOpen] = React.useState(false);
  const [waifuModalImage, setWaifuModalImage] = React.useState('');

  // Rate Waifu Form
  const [rateOpen, setRateOpen] = React.useState(false);

  const handleRateFormOpen = (waifuId: any) => {
    setRateWaifuId(waifuId);
    setRateOpen(true);
  }

  const handleRateFormClose = () => {
    setRateOpen(false);
  }

  function addWaifuFavorite(waifuId: string, userId: string) {
    if(isAuthenticated) {
      favoriteWaifu({variables:{waifuId: waifuId, userId: userId}});
      setAuthError(false);
    } else {
      setAuthError(true);
    }
  }

  function rateNewWaifu(data: any) {
    if(isAuthenticated) {
      rateWaifu({variables:{waifuId: rateWaifuId, rating: parseInt(data.rating.trim()), mommyRating: parseInt(data.mommyRating.trim()), userId: username}})
      setAuthError(false)
      handleRateFormClose()
    } else {
      setAuthError(true)
    }
  }

  function addNewComment(data: any) {
    if(isAuthenticated) {
      publishWaifuComment({variables:{waifuId: waifuId['waifuId'], text: data.comment, userId: username}})
      setAuthError(false)
      setShowCommentForm(false)
      commentReset()
    } else {
      setAuthError(true)
    }
  }

  const [favoriteWaifu, { data: favoriteData, error: favoriteWaifuError }] = useMutation(FAVORITEWAIFU, {
    refetchQueries: [
      {
        query: WAIFU,
        variables: { id: waifuId['waifuId'] }
      }
    ]
  });

  const [publishWaifuComment, { data: commentWaifuData, error: commentWaifuError }] = useMutation(COMMENTWAIFU, {
    refetchQueries: [
      {
        query: WAIFUCOMMENTS,
        variables: { limit: 8, offset: 0, waifuId: waifuId['waifuId'] }
      },
      {
        query: WAIFU,
        variables: { id: waifuId['waifuId'] }
      }
    ]
  });

  const [rateWaifu, { data: rateWaifuData, error: rateWaifuError }] = useMutation(RATEWAIFU, {
    refetchQueries: [
      {
        query: WAIFU,
        variables: { id: waifuId['waifuId'] }
      }
    ]
  });

  const handleWaifuModalOpen = (waifuImage: string) => {
    setWaifuModalImage(waifuImage)
    setWaifuModalOpen(true)
  }

  const handleWaifuModalClose = () => {
    setWaifuModalOpen(false)
  }

  const { loading, error, data, fetchMore, refetch: refetchWaifus } = useQuery(WAIFU, {
    variables: { id: waifuId['waifuId'] }
  });

  const { loading: commentsLoading, error: commentsError, data: commentData,
    fetchMore: commentsFetchMore, refetch: refetchWaifuComments } = useQuery(WAIFUCOMMENTS, {
    variables: { limit: 8, offset: 0, waifuId: waifuId['waifuId'] }
  });

  const { handleSubmit, control } = useForm();
  const { handleSubmit: handleCommentSubmit, reset: commentReset, control: commentControl } = useForm();
  const moment = Moment;
  if (loading) return <Loading/>;
  if (error) return <AlertNotification severity={'error'} text={'Error loading Waifus, please try again later!'}/>;
  if (!data) return <p>Not found</p>;

  if (user) {
    if (user.nickname != null) {
      username = user.nickname
    }
  }

  return (
    <Grid>
      <Modal
        className={classes.waifuImageModal}
        open={waifuModalOpen}
        onClose={handleWaifuModalClose}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
        <img className={classes.waifuImageModalImage} src={waifuModalImage} />
      </Modal>

      <WaifuRateForm open={rateOpen}
                     handleClose={handleRateFormClose}
                     control={control}
                     onSubmit={handleSubmit(rateNewWaifu)} />

      <Grid className={classes.root} container alignItems='center' justify='center'>
        {favoriteData && <AlertNotification severity={'success'} text={'Waifu added to Favorites!'}/>}
        {rateWaifuData && <AlertNotification severity={'success'} text={'Waifu rated!'}/>}
        {commentWaifuData && <AlertNotification severity={'success'} text={'Comment posted!'}/>}
        {authError && <AlertNotification severity={'error'} text={'You must be logged in to favorite!'}/>}

        {favoriteWaifuError && <AlertNotification severity={'warning'} text={favoriteWaifuError.message}/>}
        {rateWaifuError && <AlertNotification severity={'warning'} text={rateWaifuError.message}/>}
        {commentWaifuError && <AlertNotification severity={'warning'} text={commentWaifuError.message}/>}

        <Grid item xs={12} lg={4} xl={4} key={data.findWaifu.id}>
          <Button variant={'outlined'} color="secondary" className={classes.backButton}
                  onClick={() => history.goBack()}>
            BACK
          </Button>
          <Fade in>
            <div>

              <WaifuCard id={data.findWaifu.id}
                         name={data.findWaifu.name}
                         image={data.findWaifu.image}
                         rating={data.findWaifu.rating}
                         mommyMeter={data.findWaifu.mommyMeter}
                         comments={data.findWaifu.comments}
                         favorites={data.findWaifu.favorites}
                         userId={data.findWaifu.userId}
                         createdTime={data.findWaifu.createdTime}

                         handleRateFormOpen={() => handleRateFormOpen(data.findWaifu.id)}
                         handleWaifuModalOpen={() => handleWaifuModalOpen(data.findWaifu.image)}
                         addWaifuFavorite={() => addWaifuFavorite(data.findWaifu.id, username)}

                         waifuImageHeight={700} />

              <Card className={classes.waifu}>
                <CardContent>
                  <Typography gutterBottom variant='h5' component='div'>
                    Comments
                    {
                      isAuthenticated && (
                        <Button className={classes.commentButton} variant="contained" color="primary"
                                onClick={() => {setShowCommentForm(true)}}>
                          + NEW COMMENT
                        </Button>
                      )
                    }
                    {
                      showCommentForm && (
                        <WaifuCommentForm control={commentControl} onSubmit={handleCommentSubmit(addNewComment)}/>
                      )
                    }
                  </Typography>
                  {
                    !isAuthenticated && (
                      <div>
                        <Typography color='textSecondary'>
                          Please log in to comment!
                        </Typography>
                      </div>
                    )
                  }
                  {commentData &&
                    commentData.findAllWaifuCommentsByWaifuId.map((comment: IComment) => (
                    <Comments key={comment.waifuId}
                              id={comment.waifuId}
                              entityId={comment.waifuId}
                              text={comment.text}
                              userId={comment.userId}
                              createdTime={comment.createdTime} />
                  ))}
                </CardContent>
              </Card>
            </div>
          </Fade>
        </Grid>
      </Grid>
    </Grid>
  )
}
