import React from 'react'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {Button, TextField, Typography} from '@material-ui/core'
import {Controller} from 'react-hook-form';
import {StyledDivider} from '../divider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
    },
    addWaifuCommentButton: {
      marginTop: theme.spacing(1)
    },
  }),
)

export function WaifuCommentForm(props: {control: any, onSubmit: any}) {
  const classes = useStyles()
  return (
      <div className={classes.root}>
        <Typography>
          Add a New Comment
        </Typography>
        <StyledDivider/>
        <form>
          <Controller
            name={'comment'}
            control={props.control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth={true}
                onChange={onChange}
                value={value}
                label={'Comment'}
                id="outlined-multiline-static"
                multiline
                rows={4}
                variant="filled"
              />
            )}
          />
          <Button className={classes.addWaifuCommentButton} variant="contained" color="primary" onClick={props.onSubmit}>Submit</Button>
        </form>
      </div>
  )
}
