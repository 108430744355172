import React from 'react'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {Button, Card, Modal, Switch, TextField, Typography} from '@material-ui/core';
import {StyledDivider} from '../divider';
import {Controller} from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(6),
      display: 'flex',
    },
    postFormModal: {
      height: '70%',
      width: 600,
      position: 'absolute',
      margin: 'auto',
      overflowY: 'auto',
    },
    postForm: {
      padding: theme.spacing(2),
      margin: 'auto',
    },
    addNewPostButton: {
      marginTop: theme.spacing(1)
    },
  }),
)

interface NewPost {
  open: boolean,
  handleClose: any,
  control: any,
  onSubmit: any,
}

export function NewPostForm(props: NewPost) {
  const classes = useStyles();

  return (
    <Modal
      className={classes.postFormModal}
      open={props.open}
      onClose={props.handleClose}
    >
      <Card className={classes.postForm}>
        <Typography gutterBottom variant='h5' component='div'>
          Create a New Post
        </Typography>
        <StyledDivider/>
        <form>
          <Controller
            name={'title'}
            control={props.control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth={true}
                onChange={onChange}
                value={value}
                label={'Title'}
                id="outlined-multiline-static"
                multiline
                rows={1}
                variant="filled"
              />
            )}
          />

          <br/><br/>

          <Controller
            name={'text'}
            control={props.control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth={true}
                onChange={onChange}
                value={value}
                label={'Text'}
                id="outlined-multiline-static"
                multiline
                rows={4}
                variant="filled"
              />
            )}
          />

          <br/> <br/>

          <Controller
            name={'image'}
            control={props.control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth={true}
                onChange={onChange}
                value={value}
                label={'Image URL'}
                id="outlined-multiline-static"
                multiline
                rows={1}
                variant="filled"
              />
            )}
          />

          <br/><br/>

          <Typography variant='subtitle2' color='textSecondary'>
            NSFW Image Toggle
          </Typography>

          <Controller
            name={'nsfw'}
            control={props.control}
            render={({ field: { onChange, value } }) => (
              <Switch onChange={onChange} value={value} />
            )}
          />
        </form>
        <Button className={classes.addNewPostButton} variant="contained" color="primary" onClick={props.onSubmit}>Submit</Button>
      </Card>
    </Modal>
  )
}
