import React from 'react'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {Divider} from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),
)

export function StyledDivider() {
  const classes = useStyles()
  return <Divider className={classes.root} />
}
