import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridLoading: {
      marginTop: 150,
      marginBottom: 100,
    },
    loadingImage: {
      marginTop: 84,
      maxWidth: '100%',
      maxHeight: '100%',
    },
  }),
)

function Loading() {
  const classes = useStyles()
  return (
    <div className={classes.gridLoading}>
      <Grid className={classes.loadingImage} container spacing={3} alignItems='center' justify='center'>
        <CircularProgress size={150} />
      </Grid>
    </div>
  )
}

export default Loading
