import React from 'react'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import {Card, CardContent, Divider, Fade, Typography} from '@material-ui/core'
import Link from '@material-ui/core/Link'
import {StyledDivider} from '../components/divider'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(1.4),
      marginTop: theme.spacing(8)
    },
    card: {
      marginBottom: theme.spacing(3),
    },
    divider: {
      marginBottom: theme.spacing(1),
    },
    icon: {
      maxWidth: 40,
      maxHeight: 40,
      verticalAlign: 'middle',
      marginRight: theme.spacing(1),
    },
    link: {
      color: '#0099ff',
      cursor: 'select',
      textDecoration: 'none',
    },
  }),
)

export default function About() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Fade in>
        <Grid container spacing={3} alignItems='center' justify='center'>
          <Grid item xs={12} lg={7} xl={7}>
            <Card className={classes.card}>
              <CardContent>
                <Typography gutterBottom variant='h5' component='h2'>
                  Terms of Service
                </Typography>
                <StyledDivider />
                <Typography component='p'>
                  1. This Website is offered and available to users who are 18 years of age or older.
                  By using this Website, you agree that you are 18 years or older. If you do not meet all of these
                  requirements, you must not access or use the Website

                  <br/><br/>2. Saint has the final say on everything, there is zero free speech on this website!

                  <br/><br/>3. This is a PVP zone ENABLED! You may be subject to targeted harassment and bullying and who
                  knows what else! Be careful! :)
                </Typography>
              </CardContent>
            </Card>

            <Card className={classes.card}>
              <CardContent>
                <Typography gutterBottom variant='h5' component='h2'>
                  About Animoshi
                </Typography>
                <StyledDivider />
                <Typography component='p'>
                  Welcome to Animoshi! Animoshi is just a fun cool place to hang out and talk about
                  all sorts of topics, and post your favorite waifus of course! From humble
                  beginnings, Animoshi is the passion project of a dedicated few males that just
                  wanna shoot the shit every now and again and rate some anime women/men. Wanna join
                  in? Make an account and start posting!
                </Typography>
              </CardContent>
            </Card>

            <Card className={classes.card}>
              <CardContent>
                <Typography gutterBottom variant='h5' component='h2'>
                  About Casual Hoodlums
                </Typography>
                <Divider className={classes.divider} />
                <Typography component='p'>
                  Casual Hoodlums LLC. (®™) is a group/company of like minded friends that do all
                  kinds of activities. Most of which include playing video games, talking on
                  TeamSpeak, and working on projects like this one.
                </Typography>
              </CardContent>
            </Card>

            <Card className={classes.card}>
              <CardContent>
                <Typography gutterBottom variant='h5' component='h2'>
                  Get in Touch
                </Typography>
                <Divider className={classes.divider} />

                <Typography className={classes.divider} variant='h6'>
                  <img
                    className={classes.icon}
                    src='https://cdn1.iconfinder.com/data/icons/google_jfk_icons_by_carlosjj/512/youtube.png'
                  />
                  <span>YouTube:</span>
                  <a
                    className={classes.link}
                    target='_blank'
                    href='https://www.youtube.com/channel/UCAGf9uXETws9pdx48YT6iBA'
                    rel='noreferrer'
                  >
                    {' '}
                    Saint762
                  </a>
                </Typography>

                <Typography className={classes.divider} variant='h6'>
                  <img
                    className={classes.icon}
                    src='https://cdn1.iconfinder.com/data/icons/logotypes/32/square-twitter-512.png'
                  />
                  <span>Twitter:</span>
                  <a
                    className={classes.link}
                    target='_blank'
                    href='https://twitter.com/casualhoodlums'
                    rel='noreferrer'
                  >
                    {' '}
                    Casual Hoodlums
                  </a>
                </Typography>

                <Typography className={classes.divider} variant='h6'>
                  <img
                    className={classes.icon}
                    src='https://cdn-icons-png.flaticon.com/512/2111/2111370.png'
                  />
                  <span>Discord Server:</span>
                  <a
                    className={classes.link}
                    target='_blank'
                    href='https://discord.gg/2yfAGeUcva'
                    rel='noreferrer'
                  >
                    {' '}
                    Invite Link
                  </a>
                </Typography>

                <Divider className={classes.divider} />

                <Typography className={classes.divider} variant='h6'>
                  <img
                    className={classes.icon}
                    src='https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/250_Paypal_logo-512.png'
                  />
                  <span>Like what we do? Servers aren&apos;t free! Donate:</span>
                  <form action='https://www.paypal.com/cgi-bin/webscr' method='post' target='_top'>
                    <input type='hidden' name='cmd' value='_s-xclick' />
                    <input type='hidden' name='hosted_button_id' value='T6F7958JFB7LL' />
                    <input
                      type='image'
                      src='https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif'
                      name='submit'
                      title='PayPal - The safer, easier way to pay online!'
                      alt='Donate with PayPal button'
                    />
                    <img
                      alt=''
                      src='https://www.paypal.com/en_CA/i/scr/pixel.gif'
                      width='1'
                      height='1'
                    />
                  </form>
                </Typography>

                <Typography variant='body2' color='textSecondary' align='center'>
                  {'Copyright © '}
                  <Link color='inherit' href='https://material-ui.com/'>
                    Casual Hoodlums LLC
                  </Link>{' '}
                  {new Date().getFullYear()}
                  {'.'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Fade>
    </div>
  )
}
