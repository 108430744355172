import * as React from 'react';
import {ApolloClient, ApolloProvider, HttpLink, InMemoryCache,} from '@apollo/client';
import {setContext} from '@apollo/link-context';
import {useAuth0} from '@auth0/auth0-react';
import {offsetLimitPagination} from '@apollo/client/utilities';

const AuthApolloProvider: React.FC<unknown> = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();

  let apiUrl: string

  if(process.env.NODE_ENV === 'development') {
    apiUrl = 'http://localhost:4000'
  } else {
    apiUrl = 'https://api.animoshi.com'
  }

  const link = new HttpLink({ uri: apiUrl })

  const authLink = setContext(async (_, { headers, ...rest }) => {
    let token;
    try {
      token = await getAccessTokenSilently();
    } catch (error) {
      console.log(error);
    }

    if (!token) return { headers, ...rest };

    return {
      ...rest,
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  });

  const cache = new InMemoryCache({
    typePolicies: {
      findPostsByUserId: {
        keyFields: ['userId', 'id', 'title']
      },
      Query: {
        fields: {
          findAllPosts: offsetLimitPagination(),
          findAllWaifus: offsetLimitPagination(),
          findPostsByUserId: offsetLimitPagination(),
        },

      },
    },
  });

  const client = new ApolloClient({
    link: authLink.concat(link),
    cache,
  })

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  );
};

export { AuthApolloProvider };