import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar, {SnackbarOrigin} from '@mui/material/Snackbar';
import MuiAlert, {AlertProps} from '@mui/material/Alert';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(8)
    }
  }),
)

export interface State extends SnackbarOrigin {
  open: boolean;
}

interface AlertOptions {
  severity: 'success' | 'info' | 'warning' | 'error'
  text: string
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AlertNotification(props: AlertOptions) {
  const classes = useStyles()
  const [state, setState] = React.useState<State>({
    open: true,
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal, open } = state;

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setState({open:false, horizontal:'center', vertical:'top'});
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar className={classes.root} open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical, horizontal }}>
        <Alert onClose={handleClose} severity={props.severity} sx={{ width: '100%' }}>
          {props.text}
        </Alert>
      </Snackbar>
    </Stack>
  );
}