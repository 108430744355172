import React from 'react'
import Grid from '@material-ui/core/Grid'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {Typography} from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(6),
    },
    warning: {
      marginBottom: theme.spacing(4),
    },
  }),
)

export default function Clubs() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container spacing={3} alignItems='center' justify='center'>
        <Grid item xs={12} lg={7} xl={7}>
          <div>
            <Typography align='center' color='textSecondary'>
              This nonsense is under construction!
            </Typography>
            <Typography
              className={classes.warning}
              variant='body2'
              color='textSecondary'
              align='center'
            >
              Shit aint all working quite yet! Check back soon!
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
