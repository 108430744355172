import React from 'react'
import Button from '@material-ui/core/Button'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rightButton: {
      float: 'right',
      color: 'white',
      marginLeft: 'auto',
    },
    leftButton: {
      float: 'left',
      color: 'white',
    },
  }),
)

interface leftButton {
  text: string
  onSubmit?: () => void
}

interface rightButton {
  text: string
  onSubmit?: () => void
}

export function LeftButton(props: leftButton) {
  const classes = useStyles()
  return (
    <Button className={classes.leftButton} color='primary' onClick={props.onSubmit}>
      {props.text}
    </Button>
  )
}

export function RightButton(props: rightButton) {
  const classes = useStyles()
  return (
    <Button className={classes.rightButton} color='primary' onClick={props.onSubmit}>
      {props.text}
    </Button>
  )
}
