import React from 'react'
import {useAuth0} from '@auth0/auth0-react'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {Button} from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rightButton: {
      float: 'right',
      color: 'white',
      marginLeft: 'auto',
    },
  }),
)

const LoginButton = () => {
  const classes = useStyles()
  const { loginWithRedirect } = useAuth0()

  return (
    <Button className={classes.rightButton} onClick={() => loginWithRedirect()}>
      Log In
    </Button>
  )
}

export default LoginButton
