import React from 'react'
import NavBar from './components/navBar'
import {Route, Switch} from 'react-router'
import {BrowserRouter} from 'react-router-dom'
import Home from './pages/home'
import About from './pages/about'
import Clubs from './pages/clubs'
import Events from './pages/events'
import Waifus from './pages/waifus'
import Profile from './pages/profile'
import WaifuApproval from './pages/waifuApproval'
import SingleWaifu from './pages/singleWaifu';
import SinglePost from './pages/singlePost';

const App = () => (
  <main>
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path='/'
          render={(props) => (
            <div>
              <NavBar />
              <Home />
            </div>
          )}
        />
        <Route
          exact
          path='/about'
          render={(props) => (
            <div>
              <NavBar />
              <About />
            </div>
          )}
        />
        <Route
          exact
          path='/clubs'
          render={(props) => (
            <div>
              <NavBar />
              <Clubs />
            </div>
          )}
        />
        <Route
          exact
          path='/events'
          render={(props) => (
            <div>
              <NavBar />
              <Events />
            </div>
          )}
        />
        <Route
          exact
          path='/waifus'
          render={(props) => (
            <div>
              <NavBar />
              <Waifus />
            </div>
          )}
        />
        <Route
          exact
          path='/waifu-approval'
          render={(props) => (
            <div>
              <NavBar />
              <WaifuApproval />
            </div>
          )}
        />
        <Route
          exact
          path='/profile/:userId'
          render={(props) => (
            <div>
              <NavBar />
              <Profile />
            </div>
          )}
        />
        <Route
          exact
          path='/waifu/:waifuId'
          render={(props) => (
            <div>
              <NavBar />
              <SingleWaifu />
            </div>
          )}
        />
        <Route
          exact
          path='/post/:postId'
          render={(props) => (
            <div>
              <NavBar />
              <SinglePost />
            </div>
          )}
        />
      </Switch>
    </BrowserRouter>
  </main>
)

export default App
