import React from 'react'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import {Fade} from '@material-ui/core'
import WaifuApproval from '../components/waifuApproval'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(1.4),
      marginTop: theme.spacing(8)
    },
  }),
)

export default function WaifuApprovalView() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Fade in>
        <Grid container spacing={3} alignItems='center' justify='center'>
          <Grid item xs={12} lg={12} xl={12}>
            <WaifuApproval />
          </Grid>
        </Grid>
      </Fade>
    </div>
  )
}
