import React, {useEffect, useState} from 'react'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {Button, Grid, Modal,} from '@material-ui/core'
import Loading from './loading';
import {gql, useMutation, useQuery} from '@apollo/client';
import AlertNotification from '../components/errors/generic'
import {useAuth0} from '@auth0/auth0-react';
import {useForm} from 'react-hook-form';
import {InView} from 'react-intersection-observer';
import {WaifuPostForm} from './forms/waifuPostForm';
import {WaifuRateForm} from './forms/waifuRateForm';
import {WaifuCard} from './cards/waifuCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(3),
      display: 'flex',
    },
    addWaifuButton: {
      float: 'left',
      margin: 'auto',
      position: 'relative',
      bottom: 10,
      left: 8,
      marginRight: theme.spacing(0.5)
    },
    waifuImageModal: {
      height: '90%',
      position: 'absolute',
      margin: 'auto',
      overflowY: 'auto',
    },
    waifuImageModalImage: {
      height: '100%',
      margin: 'auto',
      display: 'block',
      maxWidth: '90%',
      maxHeight: '90%',
    }
  }),
)

const WAIFUS = gql`
    query getWaifus($offset: Int!, $limit: Int!, $waifuSort: String!) {
        findAllWaifus(offset: $offset, limit: $limit, waifuSort: $waifuSort) {
            id
            name
            description
            image
            rating
            mommyMeter
            favorites
            comments
            userId
            createdTime
        }
    }
`

const FAVORITEWAIFU = gql`
    mutation favoriteWaifu($waifuId: String!, $userId: String!) {
        favoriteWaifu(input: {
            waifuId: $waifuId,
            userId: $userId   
        })
    }
`;

const RATEWAIFU = gql`
    mutation rateWaifu($waifuId: String!, $userId: String!, $rating: Int!, $mommyRating: Int!) {
        rateWaifu(input: {
            waifuId: $waifuId,
            userId: $userId,
            rating: $rating,
            mommyRating: $mommyRating
        }) {
            id
        }
    }
`;

const POSTWAIFU = gql`
    mutation publishWaifu($name: String!, $description: String!, $image: String!, $userId: String!) {
        publishWaifu(input: {
            name: $name,
            description: $description,
            image: $image,
            userId: $userId
        }) {
          id
        }
    }
`;

interface IWaifu {
  id: string
  name: string
  description: string
  image: string
  rating: string
  mommyMeter: string
  favorites: string
  userId: string
  comments: string
  createdTime: string
  updatedTime: string
}

export default function Waifu() {
  let username = 'Anon';
  const classes = useStyles();
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [authError, setAuthError] = useState(false)
  const [rateWaifuId, setRateWaifuId] = useState(null);

  const [trendingSortButton, setTrendingSortButton] = useState('contained');
  const [topRatedButton, setTopRatedButton] = useState('outlined');
  const [topMommyButton, setTopMommyButton] = useState('outlined');


  // Post New Waifu Form
  const [open, setOpen] = React.useState(false)

  const handleFormOpen = () => {
    setOpen(true)
  }

  const handleFormClose = () => {
    setOpen(false)
  }

  const [waifuModalOpen, setWaifuModalOpen] = React.useState(false)
  const [waifuModalImage, setWaifuModalImage] = React.useState('')

  // Rate Waifu Form
  const [rateOpen, setRateOpen] = React.useState(false)

  const handleRateFormOpen = (waifuId: any) => {
    setRateWaifuId(waifuId)
    setRateOpen(true)
  }

  const handleRateFormClose = () => {
    setRateOpen(false)
  }

  function addWaifuFavorite(waifuId: string, userId: string) {
    if(isAuthenticated) {
      favoriteWaifu({variables:{waifuId: waifuId, userId: userId}})
      setAuthError(false)
    } else {
      setAuthError(true)
    }
  }

  function postNewWaifu(data: any) {
    if(isAuthenticated) {
      postWaifu({variables:{name: data.fullname.trim(), description: '', image: data.image.trim(), userId: username}})
      setAuthError(false)
      handleFormClose()
    } else {
      setAuthError(true)
    }
  }

  function rateNewWaifu(data: any) {
    if(isAuthenticated) {
      rateWaifu({variables:{waifuId: rateWaifuId, rating: parseInt(data.rating.trim()), mommyRating: parseInt(data.mommyRating.trim()), userId: username}})
      setAuthError(false)
      handleRateFormClose()
    } else {
      setAuthError(true)
    }
  }

  const [waifuLimit, setWaifuLimit] = useState(8)
  const [waifuOffset, setWaifuOffset] = useState(0)
  const [waifuSort, setWaifuSort] = useState('createdTime')

  const [favoriteWaifu, { data: favoriteData, error: favoriteWaifuError }] = useMutation(FAVORITEWAIFU, {
    refetchQueries: [
      {
        query: WAIFUS,
        variables: { offset: 0, limit: waifuLimit, waifuSort: waifuSort }
      }
    ]
  });

  const [postWaifu, { data: postWaifuData, error: postWaifuError }] = useMutation(POSTWAIFU, {
    refetchQueries: [
      {
        query: WAIFUS,
        variables: { offset: 0, limit: waifuLimit, waifuSort: waifuSort }
      }
    ]
  });

  const [rateWaifu, { data: rateWaifuData, error: rateWaifuError }] = useMutation(RATEWAIFU, {
    refetchQueries: [
      {
        query: WAIFUS,
        variables: { offset: 0, limit: waifuLimit, waifuSort: waifuSort }
      }
    ]
  });

  useEffect(() => {
    refetchWaifus({
      offset: 0,
      limit: waifuLimit,
      waifuSort: waifuSort
    })
  }, [waifuSort]);

  const handleChangeSort = (sort: string) => {
    if(sort === 'createdTime') {
      setTrendingSortButton('contained')
      setTopRatedButton('outlined')
      setTopMommyButton('outlined')
    }

    if(sort === 'rating') {
      setTrendingSortButton('outlined')
      setTopRatedButton('contained')
      setTopMommyButton('outlined')
    }

    if(sort === 'mommyMeter') {
      setTrendingSortButton('outlined')
      setTopRatedButton('outlined')
      setTopMommyButton('contained')
    }

    setWaifuSort(sort)
  }

  const handleWaifuModalOpen = (waifuImage: string) => {
    setWaifuModalImage(waifuImage)
    setWaifuModalOpen(true)
  }

  const handleWaifuModalClose = () => {
    setWaifuModalOpen(false)
  }

  const { handleSubmit, control } = useForm();
  const { loading, error, data, fetchMore, refetch: refetchWaifus } = useQuery(WAIFUS, {
    variables: { offset: 0, limit: 8, waifuSort: waifuSort },
  });
  if (loading) return <Loading/>;
  if (error) return <AlertNotification severity={'error'} text={'Error loading Waifus, please try again later!'}/>;
  if (!data) return <p>Not found</p>;

  if (user) {
    if (user.nickname != null) {
      username = user.nickname
    }
  }

  return (
    <Grid>
      <Modal
        className={classes.waifuImageModal}
        open={waifuModalOpen}
        onClose={handleWaifuModalClose}
      >
        <img className={classes.waifuImageModalImage} src={waifuModalImage} />
      </Modal>

      <WaifuPostForm open={open}
                     handleClose={handleFormClose}
                     control={control}
                     onSubmit={handleSubmit(postNewWaifu)} />

      <WaifuRateForm open={rateOpen}
                     handleClose={handleRateFormClose}
                     control={control}
                     onSubmit={handleSubmit(rateNewWaifu)} />

      <div>
        {
          isAuthenticated && (
            <Button className={classes.addWaifuButton} variant="contained" color="primary"
                    onClick={() => {handleFormOpen()}}>
              + NEW WAIFU
            </Button>
          )
        }
        <Button className={classes.addWaifuButton} variant={trendingSortButton as 'text' | 'contained' | 'outlined' | undefined} color="secondary"
                onClick={() => {handleChangeSort('createdTime')}}>
          TRENDING
        </Button>
        <Button className={classes.addWaifuButton} variant={topRatedButton as 'text' | 'contained' | 'outlined' | undefined} color="secondary"
                onClick={() => {handleChangeSort('rating')}}>
          TOP WAIFU
        </Button>
        <Button className={classes.addWaifuButton} variant={topMommyButton as 'text' | 'contained' | 'outlined' | undefined} color="secondary"
                onClick={() => {handleChangeSort('mommyMeter')}}>
          TOP MOMMY
        </Button>
      </div>
    <Grid className={classes.root} container alignItems='center'>
      {favoriteData && <AlertNotification severity={'success'} text={'Waifu added to Favorites!'}/>}
      {postWaifuData && <AlertNotification severity={'success'} text={'Waifu posted for review! An admin will review the Waifu and approve it shortly!'}/>}
      {rateWaifuData && <AlertNotification severity={'success'} text={'Waifu rated!'}/>}
      {authError && <AlertNotification severity={'error'} text={'You must be logged in to favorite!'}/>}

      {favoriteWaifuError && <AlertNotification severity={'warning'} text={favoriteWaifuError.message}/>}
      {postWaifuError && <AlertNotification severity={'warning'} text={postWaifuError.message}/>}
      {rateWaifuError && <AlertNotification severity={'warning'} text={rateWaifuError.message}/>}

      {data.findAllWaifus.map((waifu: IWaifu) => (
        <Grid item xs={12} lg={3} xl={3} key={waifu.id}>
          <WaifuCard id={waifu.id}
                     name={waifu.name}
                     image={waifu.image}
                     rating={waifu.rating}
                     mommyMeter={waifu.mommyMeter}
                     comments={waifu.comments}
                     favorites={waifu.favorites}
                     userId={waifu.userId}
                     createdTime={waifu.createdTime}

                     handleRateFormOpen={() => handleRateFormOpen(waifu.id)}
                     handleWaifuModalOpen={() => handleWaifuModalOpen(waifu.image)}
                     addWaifuFavorite={() => addWaifuFavorite(waifu.id, username)}

                     waifuImageHeight={500}/>
        </Grid>
      ))}
      {data && (
        <InView
          onChange={async (inView) => {
            const currentLength = data.findAllWaifus.length || 0;
            if (inView) {
              setWaifuLimit(currentLength + 8)
              setWaifuOffset(currentLength)

              await fetchMore({
                variables: {
                  offset: currentLength,
                  limit: currentLength + 8,
                  waifuSort: waifuSort
                },
              });
            }
          }}
        />
      )}
    </Grid>
  </Grid>
  )
}
