import React, {useState} from 'react'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {Button, Card, CardActionArea, CardContent, CardMedia, Grid, Typography,} from '@material-ui/core'
import Loading from './loading';
import {gql, useMutation, useQuery} from '@apollo/client';
import AlertNotification from '../components/errors/generic'
import {useAuth0} from '@auth0/auth0-react';
import {PostedTime} from './general/postedTime';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(3),
      display: 'flex',
    },
    waifu: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    waifuImage: {
      height: 500,
    },
    userId: {
      position: 'relative',
      bottom: 0,
      right: -10,
      margin: theme.spacing(1, 1, 1, 1),
    },
    rateButton: {
      float: 'right',
      position: 'relative',
      bottom: 3,
      marginRight: theme.spacing(0.5)
    }
  }),
)

const WAIFUS = gql`
    {
        findAllPendingWaifus {
            id
            name
            description
            image
            rating
            status
            favorites
            userId
            createdTime
        }
    }
`

const APPROVEWAIFU = gql`
    mutation approveWaifu($waifuId: String!, $userId: String!) {
        approveWaifu(input: {
            waifuId: $waifuId,
            userId: $userId
        }) {
          id
        }
    }
`;

interface IWaifu {
  id: string
  name: string
  description: string
  image: string
  rating: string
  favorites: string
  userId: string
  status: string
  createdTime: string
  updatedTime: string
}

export default function WaifuApproval() {
  let username = 'Anon';
  const classes = useStyles();
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [authError, setAuthError] = useState(false)

  if (user) {
    if (user.nickname != null) {
      username = user.nickname
    }
  }

  function addApproveWaifu(waifuId: string, userId: string) {
    if(isAuthenticated && user?.nickname === 'saint') {
      approveWaifu({variables:{waifuId: waifuId, userId: userId}})
      setAuthError(false)
    } else {
      setAuthError(true)
    }
  }

  const [approveWaifu, { data: approveData }] = useMutation(APPROVEWAIFU, {
    refetchQueries: [{ query: WAIFUS }]
  });

  const { loading, error, data } = useQuery(WAIFUS);
  if (loading) return <Loading/>;
  if (error) return <AlertNotification severity={'error'} text={'Error loading Waifus, please try again later!'}/>;
  if (!data) return <p>Not found</p>;
  if(username !== 'saint') return <AlertNotification severity={'error'} text={'How did u get here ? ?'}/>;

  return (
    <Grid className={classes.root} container alignItems='center'>

      {authError && <AlertNotification severity={'error'} text={'You must be logged in to favorite!'}/>}
      {approveData && <AlertNotification severity={'success'} text={'Waifu approved!'}/>}

      {data.findAllPendingWaifus.map((waifu: IWaifu) => (
        <Grid item xs={12} lg={3} xl={3} key={waifu.id}>
          <Card className={classes.waifu}>
            <CardContent>
              <Typography gutterBottom variant='h5' component='div'>
                {waifu.name}
                <Button className={classes.rateButton} variant="contained" color="primary">
                  DENY
                </Button>
                <Button className={classes.rateButton} variant="contained" color="primary"
                  onClick={() => {
                    addApproveWaifu(waifu.id, username)
                  }}>
                  APPROVE
                </Button>
              </Typography>
            </CardContent>
            <CardActionArea>
              <CardMedia className={classes.waifuImage} component='img' image={waifu.image} />
            </CardActionArea>
            <Typography variant='subtitle2' color='textSecondary' className={classes.userId}>

              <PostedTime createdTime={waifu.createdTime}
                          userId={waifu.userId}
                          suffix={true} />

            </Typography>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}
