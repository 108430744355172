import React from 'react'
import Grid from '@material-ui/core/Grid'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {AppBar, Card, CardContent, Divider, Fade, MenuItem, Paper, Toolbar, Typography,} from '@material-ui/core'
import {Link} from 'react-router-dom'
import {StyledDivider} from '../components/divider'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(6),
    },
    card: {
      marginBottom: theme.spacing(3),
    },
    warning: {
      marginBottom: theme.spacing(4),
    },
    logo: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
    menuItem: {
      padding: theme.spacing(2, 5, 2, 5),
    },
  }),
)

export default function Events() {
  const classes = useStyles()

  return (
    <Fade in>
      <div className={classes.root}>
        <Grid container spacing={3} alignItems='center' justify='center'>
          <Grid item xs={12} lg={7} xl={7}>
            <div>
              <Typography align='center' color='textSecondary'>
                This nonsense is under construction!
              </Typography>
              <Typography
                className={classes.warning}
                variant='body2'
                color='textSecondary'
                align='center'
              >
                Shit aint all working quite yet! Check back soon! Or never!
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems='flex-start' justify='center'>
          <Grid item xs={12} lg={2} xl={2}>
            <AppBar position='static'>
              <Toolbar>
                <h3 className={classes.logo}>MANAGE</h3>
              </Toolbar>
            </AppBar>
            <Paper>
              <div>
                <MenuItem component={Link} className={classes.menuItem} to='/events'>
                  CREATE
                </MenuItem>
                <Divider />
                <MenuItem component={Link} className={classes.menuItem} to='/events'>
                  VIEW INVITES
                </MenuItem>
                <Divider />
              </div>
              <MenuItem component={Link} className={classes.menuItem} to='/events'>
                MEMBERS
              </MenuItem>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={7} xl={7}>
            <AppBar position='static'>
              <Toolbar>
                <h3 className={classes.logo}>UPCOMING EVENTS</h3>
              </Toolbar>
            </AppBar>
            <Card className={classes.card}>
              <Grid item xs={12}>
                <CardContent className={classes.logo}>
                  <Typography gutterBottom variant='h6'>
                    The Boys watch AOT
                  </Typography>
                  <StyledDivider />
                  <Typography>Watch party event for the boys watching attack on titan</Typography>
                </CardContent>
              </Grid>
            </Card>

            <Card className={classes.card}>
              <Grid item xs={12}>
                <CardContent className={classes.logo}>
                  <Typography gutterBottom variant='h6'>
                    The Boys watch Steins Gate
                  </Typography>
                  <StyledDivider />
                  <Typography>Wow steins gate? pog.</Typography>
                </CardContent>
              </Grid>
            </Card>

            <Card className={classes.card}>
              <Grid item xs={12}>
                <CardContent className={classes.logo}>
                  <Typography gutterBottom variant='h6'>
                    Big Fat Age of Empires XD
                  </Typography>
                  <StyledDivider />
                  <Typography>Saint&apos;s gonna rush mango&apos;s friends</Typography>
                </CardContent>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </div>
    </Fade>
  )
}
