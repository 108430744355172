import React from 'react'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {Button, Card, CardActionArea, CardContent, CardMedia, LinearProgress, Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {StyledDivider} from '../divider';
import {Likes} from '../general/likes';
import {Comments} from '../general/comments';
import {PostedTime} from '../general/postedTime';
import {useAuth0} from '@auth0/auth0-react';

export interface StyleProps {
  waifuImageHeight: number;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(6),
      display: 'flex',
    },
    waifu: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    waifuName: {
      color: 'white',
      textDecoration: 'none'
    },
    rateButton: {
      float: 'right',
      position: 'relative',
      bottom: 3,
    },
    meters: {
      alignItems: 'center'
    },
    meterBar: {
      maxWidth: '80%',
    },
    meterText: {
      position: 'relative',
      bottom: 18,
      right: 5,
      float: 'right',
      margin: 'auto'
    },
    waifuImage: {
      height: ({waifuImageHeight}) => waifuImageHeight
    },
    waifuLink: {
      color: '#C3C3C3',
      textDecoration: 'none'
    },
    userId: {
      position: 'relative',
      bottom: 0,
      right: -10,
      margin: theme.spacing(1, 1, 1, 1),
    },
  }),
)

interface Waifu {
  id: string,
  name: string,
  image: string,
  rating: string,
  mommyMeter: string,
  comments: string,
  favorites: string,
  userId: string,
  createdTime: string,

  handleRateFormOpen: any,
  handleWaifuModalOpen: any,
  addWaifuFavorite: any,

  waifuImageHeight: number
}

export function WaifuCard(props: Waifu) {
  const classes = useStyles(props);
  const { isAuthenticated } = useAuth0();

  return (
    <Card className={classes.waifu}>
      <CardContent>
        <Typography gutterBottom variant='h5' component='div'>
          <Link className={classes.waifuName} to={`/waifu/${props.id}`}>
            {props.name}
          </Link>
          {
            isAuthenticated && (
              <Button className={classes.rateButton} variant="contained" color="primary" onClick={props.handleRateFormOpen}>
                RATE
              </Button>
            )
          }
        </Typography>
        <StyledDivider/>
        <Typography gutterBottom variant='h6' component='div'>
          Waifu Rating
        </Typography>
        <div className={classes.meters}>
          <LinearProgress className={classes.meterBar} color="primary" variant="determinate" value={parseInt(props.rating) * 10} />
          <Typography className={classes.meterText} gutterBottom variant='h6' component='div'>
            {props.rating}/10
          </Typography>
        </div>

        <br/>
        <Typography gutterBottom variant='h6' component='div'>
          Mommy Meter
        </Typography>
        <LinearProgress className={classes.meterBar} color="secondary" variant="determinate" value={parseInt(props.mommyMeter) * 10} />
        <Typography className={classes.meterText} gutterBottom variant='h6' component='div'>
          {props.mommyMeter}/10
        </Typography>
      </CardContent>
      <CardActionArea onClick={props.handleWaifuModalOpen}>
        <CardMedia className={classes.waifuImage} component='img' image={props.image} />
      </CardActionArea>
      <Typography variant='subtitle2' color='textSecondary' className={classes.userId}>

        <Likes likes={parseInt(props.favorites)}
               id={props.id}
               userId={props.userId}
               onClick={props.addWaifuFavorite} />

        <Link className={classes.waifuLink} to={`/waifu/${props.id}`}>
          <Comments comments={parseInt(props.comments)}
                    id={props.id}
                    userId={props.userId}
                    onClick={() => console.log('')} />
        </Link>

        <PostedTime createdTime={props.createdTime}
                    userId={props.userId}
                    suffix={true} />

      </Typography>
    </Card>
  )
}
