import React, {useEffect, useState} from 'react'
import Grid from '@material-ui/core/Grid'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {AppBar, Card, CardContent, CardMedia, Typography} from '@material-ui/core'
import {gql, useMutation, useQuery} from '@apollo/client';
import {useParams} from 'react-router-dom';
import Loading from '../components/loading';
import AlertNotification from '../components/errors/generic';
import Moment from 'moment';
import {PostCard} from '../components/cards/postCard';
import {InView} from 'react-intersection-observer';
import {useAuth0} from '@auth0/auth0-react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(1.4),
      marginTop: theme.spacing(8),
      flexDirection: 'column'
    },
    card: {
      marginBottom: theme.spacing(3),
      flexDirection: 'column'
    },
    warning: {
      marginBottom: theme.spacing(4),
    },
    details: {
      flex: '1 0 auto',
    },
    heading: {
      color: 'white',
      padding: theme.spacing(2),
      textAlign: 'center',
      marginBottom: theme.spacing(1.5)
    }
  }),
)

interface IPost {
  id: string,
  content: string,
  title: string,
  image: string,
  video: string,
  likes: number,
  comments: number,
  nsfwToggle?: number,
  userId: string,
  createdTime: string
}

const PROFILE = gql`
    query getProfile($userId: String!) {
        findProfile(userId: $userId) {
            id
            userId
            lastActive
            avatar
            createdTime
        }
    }
`

const POSTCOUNT = gql`
    query getPostCount($userId: String!) {
        findPostCountByUserId(userId: $userId)
    }
`

const WAIFUCOUNT = gql`
    query getWaifuCount($userId: String!) {
        findWaifuCountByUserId(userId: $userId)
    }
`

const WAIFUFAVORITECOUNT = gql`
    query getWaifuFavoriteCount($userId: String!) {
        findWaifusFavoritedCountByUserId(userId: $userId)
    }
`

const WAIFURATEDCOUNT = gql`
    query getWaifuRatedCount($userId: String!) {
        findWaifusRatedCountByUserId(userId: $userId)
    }
`

const POSTS = gql`
    query getPosts($offset: Int, $limit: Int, $userId: String) {
        findPostsByUserId(offset: $offset, limit: $limit, userId: $userId) {
            id
            content
            title
            image
            video
            likes
            comments
            nsfwToggle
            userId
            createdTime
        }
    }
`

const LIKEPOST = gql`
    mutation likePost($postId: String!, $userId: String!) {
        likePost(input: {
            postId: $postId,
            userId: $userId
        })
    }
`;

export default function Profile() {
  let username = 'Anon';
  const classes = useStyles();
  const { user, isAuthenticated } = useAuth0();
  const userId = useParams();
  const moment = Moment;
  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
  const [authError, setAuthError] = useState(false)

  if (user) {
    if (user.nickname != null) {
      username = user.nickname
    }
  }

  const [postLimit, setPostLimit] = useState(8)
  const [postOffset, setPostOffset] = useState(0)

  // ADD POST LIKE
  async function addLikePost(postId: string, userId: string) {
    if (isAuthenticated) {
      likePost({variables: {postId: postId, userId: userId}})
      setAuthError(false)
    } else {
      setAuthError(true)
      await sleep(6000)
      setAuthError(false)
    }
  }

  const [likePost, { data: likeData, error: likePostError }] = useMutation(LIKEPOST, {
    refetchQueries: [
      {
        query: POSTS,
        variables: { offset: 0, limit: postLimit }
      }
    ]
  });

  const { loading: postsLoading, error: postsEror, data: postsData, fetchMore, refetch, client } = useQuery(POSTS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
    variables: { offset: 0, limit: 8, userId: userId['userId'] },
  });

  const { loading, error, data } = useQuery(PROFILE, {
    variables: { userId: userId['userId'] }
  });

  const { data: postCountData, loading: postCountLoading } = useQuery(POSTCOUNT, {
    variables: { userId: userId['userId'] }
  });

  const { data: waifuCountData, loading: waifuCountLoading } = useQuery(WAIFUCOUNT, {
    variables: { userId: userId['userId'] }
  });

  const { data: waifuRatedCountData, loading: waifuRatedCountLoading } = useQuery(WAIFURATEDCOUNT, {
    variables: { userId: userId['userId'] }
  });

  const { data: waifuFavoritedCountData, loading: waifuFavoritedCountLoading } = useQuery(WAIFUFAVORITECOUNT, {
    variables: { userId: userId['userId'] }
  });

  useEffect(() => {
    client.resetStore()
    refetch({offset: 0, limit: postLimit, userId: userId['userId']})
  }, [likeData])

  useEffect(() => {
    client.resetStore()
    refetch({offset: 0, limit: 8, userId: userId['userId']})
    window.scrollTo(0,0);
  }, [userId['userId']])

  if (loading) return <Loading/>;
  if (postsLoading) return <Loading/>;
  if (postCountLoading) return <Loading/>;
  if (waifuCountLoading) return <Loading/>;
  if (waifuRatedCountLoading) return <Loading/>;
  if (waifuFavoritedCountLoading) return <Loading/>;
  if (error) return <AlertNotification severity={'error'} text={'Error loading profile, please try again later!'}/>;
  if (!data) return <AlertNotification severity={'warning'} text={'Profile not found!'}/>;
  if (data.findProfile === null) return <AlertNotification severity={'warning'} text={'Profile not found!'}/>;

  return (
    <div className={classes.root}>

      {likeData && <AlertNotification severity={'success'} text={'Post liked!'}/>}
      {likePostError && <AlertNotification severity={'warning'} text={likePostError.message}/>}

      <Grid container spacing={3} alignItems='center' justify='center'>
        <Grid item xs={12} lg={9} xl={9}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item>
                  <CardMedia>
                    <img src={data?.findProfile.avatar} />
                  </CardMedia>
                </Grid>

                <Grid className={classes.details} item>
                  <Typography gutterBottom variant='h5'>
                    {data?.findProfile.userId}
                  </Typography>
                  <Typography>
                    Last Active — {moment(parseInt(data?.findProfile.lastActive)).fromNow(false)}
                  </Typography>
                  <Typography>
                    Post Count — {postCountData?.findPostCountByUserId}
                  </Typography>
                  <Typography>
                    Waifus Posted — {waifuCountData?.findWaifuCountByUserId}
                  </Typography>
                  <Typography>
                    Waifus Rated — {waifuRatedCountData?.findWaifusRatedCountByUserId}
                  </Typography>
                  <Typography>
                    Waifus Favorited — {waifuFavoritedCountData?.findWaifusFavoritedCountByUserId}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={3} alignItems='flex-start' justify='center' direction='row' >
        <Grid item xs={12} lg={3} xl={3}>
          <AppBar position="static" className={classes.heading}>
            <Typography variant='subtitle2'>
              POSTS
            </Typography>
          </AppBar>
          {postsData &&
            postsData.findPostsByUserId.map((post: IPost) => (

              <PostCard key={`${post.id}-${userId['userId']}`}
                        id={post.id}
                        title={post.title}
                        content={post.content}
                        image={post.image}
                        video={post.video}
                        likes={post.likes}
                        comments={post.comments}
                        linkEnabled={true}
                        nsfwToggle={post.nsfwToggle}
                        enableNfswToggle={true}
                        userId={post.userId}
                        createdTime={post.createdTime}
                        likePost={() => addLikePost(post.id, username)} />
              ))
          }
        </Grid>

        <Grid item xs={12} lg={3} xl={3}>
          <AppBar position="static" className={classes.heading}>
            <Typography variant='subtitle2'>
            FAVORITED WAIFUS
            </Typography>
          </AppBar>
        </Grid>

        <Grid item xs={12} lg={3} xl={3}>
          <AppBar position="static" className={classes.heading}>
            <Typography variant='subtitle2'>
              WAIFU RATINGS
            </Typography>
          </AppBar>
        </Grid>
      </Grid>

      {postsData && (
        <InView
          onChange={async (inView) => {
            const currentLength = postsData.findPostsByUserId.length || 0;
            if (inView) {
              setPostLimit(currentLength + 8)
              setPostOffset(currentLength)

              await fetchMore({
                variables: {
                  offset: currentLength,
                  limit: currentLength + 8,
                },
              });
            }
          }}
        />
      )}
    </div>
  )
}
