import React from 'react'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import {LeftButton} from './button'
import {Link} from 'react-router-dom'
import {Hidden, Menu, MenuItem} from '@material-ui/core'
import {useAuth0} from '@auth0/auth0-react'
import LoginButton from './loginButton'
import LogoutButton from './logoutButton'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
    logoRight: {
      float: 'right',
      margin: 'auto',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
    link: {
      float: 'right',
      color: 'white',
      marginLeft: 'auto',
    },
    basicLink: {
      color: 'white',
      textDecoration: 'none',
    },
  }),
)

export default function NavBar() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const classes = useStyles()
  const { isAuthenticated, user } = useAuth0()
  return (
    <div>
      <AppBar position='fixed'>
        <Toolbar>
          <Hidden smDown>
            <h2 className={classes.logo}>アニモシ ANIMOSHI</h2>
          </Hidden>

          <Hidden smDown>
            <Link to={'/'}>
              <LeftButton text={'HOME'} />
            </Link>

            <Link to={'/waifus'}>
              <LeftButton text={'WAIFUS'} />
            </Link>

            <Link to={'/about'}>
              <LeftButton text={'ABOUT'} />
            </Link>

            {isAuthenticated && user?.nickname === 'saint' && (
              <Link to={'/waifu-approval'}>
                <LeftButton text={'ADMIN'} />
              </Link>
            )}

            {!isAuthenticated && <LoginButton />}

            {isAuthenticated && (
              <div className={classes.link}>
                <Link to={'/profile/' + user?.nickname}>
                  <LeftButton text={'PROFILE'} />
                </Link>
                <LogoutButton />
              </div>
            )}
          </Hidden>

          <Hidden mdUp>
            <IconButton
              className={classes.link}
              id='menu-button'
              size='large'
              edge='start'
              color='inherit'
              aria-label='menu'
              sx={{ mr: 2 }}
              onClick={handleClick}
            >
              <MenuIcon />
            </IconButton>
            <Hidden mdUp>
              <h2 className={classes.logo}>アニモシ</h2>
            </Hidden>
            <Menu
              className={classes.link}
              id='basic-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'menu-button',
              }}
            >
              <Link to={'/'} className={classes.basicLink}>
                <MenuItem onClick={handleClose}>HOME</MenuItem>
              </Link>

              <Link to={'/waifus'} className={classes.basicLink}>
                <MenuItem onClick={handleClose}>WAIFUS</MenuItem>
              </Link>

              <Link to={'/about'} className={classes.basicLink}>
                <MenuItem onClick={handleClose}>ABOUT</MenuItem>
              </Link>
            </Menu>
            {!isAuthenticated && <LoginButton />}

            {isAuthenticated && (
              <div className={classes.link}>
                <Link to={'/profile/' + user?.nickname}>
                  <LeftButton text={'PROFILE'} />
                </Link>
                <LogoutButton />
              </div>
            )}
          </Hidden>
        </Toolbar>
      </AppBar>
    </div>
  )
}
