import React from 'react'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {CardMedia, Modal} from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cover: {
      height: '100%',
      width: '100%',
      resizeMode: 'cover'
    },
    modal: {
      height: '90%',
      position: 'absolute',
      margin: 'auto',
      overflowY: 'auto',
    },
    modalImage: {
      margin: 'auto',
      display: 'block',
      maxWidth: '90%',
      maxHeight: '90%',
      resizeMode: 'cover'
    },
  }),
)

interface childProps {
  postImage: string
  nsfwToggle?: number
  enableNfswToggle: boolean
}

const PostImage = (props: childProps) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
        <img className={classes.modalImage} src={props.postImage} />
      </Modal>
      <div>
        <div onClick={() => handleOpen()}>
          {
            (props.enableNfswToggle && props.nsfwToggle === 1) && (
              <CardMedia className={classes.cover} component='img' image="https://i.imgur.com/YX2frXk.png" />
            )
          }

          {
            (props.enableNfswToggle && props.nsfwToggle !== 1) && (
              <CardMedia className={classes.cover} component='img' image={props.postImage} />
            )
          }

          {
            (!props.enableNfswToggle) && (
              <CardMedia className={classes.cover} component='img' image={props.postImage} />
            )
          }
        </div>
      </div>
    </div>
  )
}

export default PostImage
