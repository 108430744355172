import React, {useState} from 'react'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {gql, useMutation, useQuery} from '@apollo/client'
import Loading from './loading'
import {InView} from 'react-intersection-observer';
import AlertNotification from './errors/generic';
import {useAuth0} from '@auth0/auth0-react';
import {PostCard} from './cards/postCard';
import {Button} from '@material-ui/core';
import {NewPostForm} from './forms/newPostForm';
import {useForm} from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(6),
      display: 'flex',
    },
    addPostButton: {
      marginBottom: theme.spacing(1),
    },
  }),
)

const POSTS = gql`
  query getPosts($offset: Int, $limit: Int) {
    findAllPosts(offset: $offset, limit: $limit) {
        id
        content
        title
        image
        video
        likes
        comments
        nsfwToggle
        userId
        createdTime
    }
  }
`

const LIKEPOST = gql`
    mutation likePost($postId: String!, $userId: String!) {
        likePost(input: {
            postId: $postId,
            userId: $userId
        })
    }
`;

const NEWPOST = gql`
    mutation publishPost($title: String!, $content: String!, $image: String!, $video: String!, $nsfwToggle: Int!, $userId: String!) {
        publishPost(input: {
            title: $title,
            content: $content,
            image: $image,
            video: $video,
            nsfwToggle: $nsfwToggle,
            userId: $userId
        }) {
            id
        }
    }
`;

interface IPost {
  id: string,
  content: string,
  title: string,
  image: string,
  video: string,
  likes: number,
  comments: number,
  nsfwToggle?: number,
  userId: string,
  createdTime: string
}

interface INewPost{
  title?: string,
  text?: string,
  image?: string,
  nsfw: boolean
}

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export default function Post() {
  let username = 'Anon';
  const classes = useStyles()
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [authError, setAuthError] = useState(false)

  async function addLikePost(postId: string, userId: string) {
    if (isAuthenticated) {
      likePost({variables: {postId: postId, userId: userId}})
      setAuthError(false)
    } else {
      setAuthError(true)
      await sleep(6000)
      setAuthError(false)
    }
  }

  const [postLimit, setPostLimit] = useState(8)
  const [postOffset, setPostOffset] = useState(0)

  const [likePost, { data: likeData, error: likePostError }] = useMutation(LIKEPOST, {
    refetchQueries: [
      {
        query: POSTS,
        variables: { offset: 0, limit: postLimit }
      }
    ]
  });

  // ~~~~~~~~~ NEW POSTS ~~~~~~~~~
  const { handleSubmit, control, reset } = useForm<INewPost>();

  const [postFormOpen, setPostFormOpen] = React.useState(false)

  const handlePostFormOpen = () => {
    setPostFormOpen(true)
  }

  const handlePostFormClose = () => {
    setPostFormOpen(false)
  }

  const [newPost, { data: newPostData, error: newPostError }] = useMutation(NEWPOST, {
    refetchQueries: [
      {
        query: POSTS,
        variables: { offset: 0, limit: postLimit }
      }
    ]
  });

  function addNewPost(data: INewPost) {
    if(isAuthenticated) {
      let title = ''
      let content = ''
      let image = ''
      let nsfw = 0

      if(data.title !== undefined) {
        title = data.title
      }

      if(data.text !== undefined) {
        content = data.text
      }

      if(data.image !== undefined) {
        image = data.image
      }

      if(data.nsfw) {
        nsfw = 1
      } else {
        nsfw = 0
      }

      newPost({variables:
          {
            title: title.trim(),
            content: content.trim(),
            image: image.trim(),
            video: '',
            nsfwToggle: nsfw,
            userId: username
          }})

      setAuthError(false)
      handlePostFormClose()
      reset()
    } else {
      setAuthError(true)
    }
  }
  // ~~~~~~~~~ NEW POSTS ~~~~~~~~~

  const { loading, error, data, fetchMore } = useQuery(POSTS, {
    variables: { offset: 0, limit: 8 },
  })

  if (loading) return <Loading />
  if (error) return <AlertNotification severity={'error'} text={'Error loading Posts, please try again later!'}/>;
  if (!data) return <p>Not found</p>

  if (user) {
    if (user.nickname != null) {
      username = user.nickname
    }
  }

  return (
    <div>

      <NewPostForm open={postFormOpen} handleClose={handlePostFormClose} control={control} onSubmit={handleSubmit(addNewPost)} />

      {likeData && <AlertNotification severity={'success'} text={'Post liked!'}/>}
      {authError && <AlertNotification severity={'error'} text={'You must be logged in to like posts!'}/>}
      {likePostError && <AlertNotification severity={'warning'} text={likePostError.message}/>}

      {newPostData && <AlertNotification severity={'success'} text={'Posted successfully!'}/>}
      {newPostError && <AlertNotification severity={'warning'} text={newPostError.message}/>}

      {
        isAuthenticated && (
          <Button className={classes.addPostButton} variant="contained" color="primary"
                  onClick={() => {handlePostFormOpen()}}>
            + NEW POST
          </Button>
        )
      }

      {data &&
        data.findAllPosts.map((post: IPost) => (
          <PostCard key={post.id}
                    id={post.id}
                    title={post.title}
                    content={post.content}
                    image={post.image}
                    video={post.video}
                    likes={post.likes}
                    comments={post.comments}
                    linkEnabled={true}
                    userId={post.userId}
                    nsfwToggle={post.nsfwToggle}
                    enableNfswToggle={true}
                    createdTime={post.createdTime}
                    likePost={() => addLikePost(post.id, username)} />
        ))}
      {data && (
        <InView
          onChange={async (inView) => {
            const currentLength = data.findAllPosts.length || 0;
            if (inView) {
              setPostLimit(currentLength + 8)
              setPostOffset(currentLength)

              await fetchMore({
                variables: {
                  offset: currentLength,
                  limit: currentLength + 8,
                },
              });
            }
          }}
          />
        )}
    </div>
  )
}
