import React from 'react'
import ReactDOM from 'react-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import {ThemeProvider} from '@material-ui/core/styles'
import App from './app'
import theme from './theme'
import {Auth0Provider} from '@auth0/auth0-react'
import {AuthApolloProvider} from './authApolloProvider';

ReactDOM.render(
  <Auth0Provider
    domain='casualhoodlums.auth0.com'
    clientId='gKXB4T93Qd5irvFX8f1L7dW0diE6qaZc'
    redirectUri={window.location.origin}
    useRefreshTokens
    cacheLocation="localstorage"
    audience="https://ch-auth/api"
  >
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthApolloProvider>
        <App/>
      </AuthApolloProvider>
    </ThemeProvider>
  </Auth0Provider>,
  document.getElementById('root'),
)
