import React from 'react'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import IconButton from '@mui/material/IconButton';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      maxHeight: 18,
      marginRight: theme.spacing(0.5),
      position: 'relative',
      bottom: 0,
      left: 2
    }
  }),
)

interface Comments {
  comments: number,
  id: string,
  userId: string,
  onClick: any
}

export function Comments(props: Comments) {
  const classes = useStyles();

  return (
    <span>
      {props.comments}
      <IconButton aria-label="favorite" onClick={() => props.onClick()}>
        <ChatBubbleIcon sx={{color:'#C3C3C3'}} fontSize={'small'} className={classes.icon}/>
      </IconButton>
    </span>
  )
}
