import React from 'react'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import {Fade, Typography} from '@material-ui/core'
import Waifu from '../components/waifu'
import {useAuth0} from '@auth0/auth0-react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(1.4),
      marginTop: theme.spacing(10)
    },
    please: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  }),
)

export default function Waifus() {
  const classes = useStyles()
  const { isAuthenticated } = useAuth0()

  return (
    <div className={classes.root}>
      <Fade in>
        <Grid container spacing={3} alignItems='center' justify='center'>
          {
            !isAuthenticated && (
              <Typography className={classes.please} color='textSecondary'>
                Looking to post a Waifu of your own? Please log in first :)!
              </Typography>
            )
          }
          <Grid item xs={12} lg={12} xl={12}>
            <Waifu />
          </Grid>
        </Grid>
      </Fade>
    </div>
  )
}
